import React from "react";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import * as styles from "./quote.module.scss";

const Quote = () => {
  const { ref, inView } = useInView({
    threshold: 0.25,
  });

  return (
    <section className={styles.outerContainer}>
      <q ref={ref}>
        <span
          className={`${styles.topText} ${inView ? styles.topAnimation : ""}`}
        >
          "Just when the caterpillar thought its life had ended...
        </span>
        <span
          className={`${styles.bottomText} ${
            inView ? styles.bottomAnimation : ""
          }`}
        >
          It turned into a butterfly!"
        </span>
      </q>
    </section>
  );
};

export default Quote;
