// extracted by mini-css-extract-plugin
export var anchor = "about-module--anchor--3DpGi";
export var about = "about-module--about--3l2Rq";
export var sectionLayout = "about-module--section-layout--2Oxld";
export var sectionText = "about-module--section-text--1hb21";
export var sectionImages = "about-module--section-images--2M94_";
export var aboutMe = "about-module--about-me--Py9Rs";
export var title = "about-module--title--2u9Z-";
export var paragraph = "about-module--paragraph--MzmgA";
export var quote = "about-module--quote--3rep5";
export var lineBreak = "about-module--line-break--1SC-_";
export var wordcloud = "about-module--wordcloud--2Fcwi";
export var profileImg = "about-module--profile-img--E3Q97";
export var logo = "about-module--logo--2JtIm";
export var psiGraduate = "about-module--psiGraduate--WSvRi";
export var logoSeparator = "about-module--logoSeparator--19tfJ";
export var psiLineBreak = "about-module--psiLineBreak--29E3e";
export var textFadeTransition = "about-module--text-fade-transition--3Kh-g";