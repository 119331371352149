import React from "react";
import * as styles from "./navbar.module.scss";

const Navbar = () => {
  return (
    <div className={styles.nav}>
      <nav className={styles.navItems}>
        <a href="/#home-anchor" className={styles.navItems__item}>
          Home
        </a>
        <a href="/#about-anchor" className={styles.navItems__item}>
          About
        </a>
        <a href="/#contact-anchor" className={styles.navItems__item}>
          Contact
        </a>
      </nav>
    </div>
  );
};

export default Navbar;
