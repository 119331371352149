// extracted by mini-css-extract-plugin
export var anchor = "contact-module--anchor--22170";
export var contact = "contact-module--contact--Nv4Uf";
export var header = "contact-module--header--VO6rt";
export var request = "contact-module--request--1n6tc";
export var info = "contact-module--info--E7pgk";
export var image = "contact-module--image--2YLRC";
export var quote = "contact-module--quote--1ll9g";
export var name = "contact-module--name--12ACu";
export var lineBreak = "contact-module--line-break--2YY6O";
export var detail = "contact-module--detail--1DaaX";
export var logoExplanation = "contact-module--logoExplanation--2ufQY";
export var textFadeTransition = "contact-module--text-fade-transition--pcldk";