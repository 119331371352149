// extracted by mini-css-extract-plugin
export var anchor = "intro-module--anchor--1bzO-";
export var intro = "intro-module--intro--2Ey14";
export var introDescription = "intro-module--intro-description--2qLZn";
export var textAndButtons = "intro-module--textAndButtons--kLtCu";
export var intro__textH1 = "intro-module--intro__textH1--6Wd20";
export var textFadeTransition = "intro-module--text-fade-transition--_EXAr";
export var intro__textH2 = "intro-module--intro__textH2--FAfAY";
export var intro__textH3 = "intro-module--intro__textH3--3QgTA";
export var imageContainer = "intro-module--imageContainer--3qHW8";
export var image = "intro-module--image--1nooW";
export var buttons = "intro-module--buttons--2SBOn";
export var btn = "intro-module--btn--1HGAI";
export var leftBtn = "intro-module--left-btn--1Bb7g";
export var text = "intro-module--text--1_fE1";