import React from "react";
import Img from "@images/butterfly.webp";
import Phone from "@images/icons/phone.png";
import Person from "@images/icons/person.png";
import Email from "@images/icons/email.png";
import Location from "@images/icons/location.png";
import * as styles from "./contact.module.scss";

const Contact = () => {
  return (
    <section className={styles.contact}>
      <div className={styles.anchor} id="contact-anchor"></div>
      <h2 className={styles.header}>Contact Seoige</h2>
      <h3 className={styles.request}>
        Please ring, email or text for an appointment
        <br /> and I will reply as soon as possible
      </h3>
      <div className={styles.detail}>
        <img src={Person} alt="Person"></img>
        <h3 className={styles.info}>Briedge McCarneyJoyce</h3>
      </div>
      <div className={styles.detail}>
        <img src={Email} alt="Email"></img>
        <h3 className={styles.info}>seoigetherapy@gmail.com</h3>
      </div>
      <div className={styles.detail}>
        <img src={Phone} alt="Phone"></img>
        <h3 className={styles.info}>(085) 822 6680</h3>
      </div>
      <div className={styles.detail}>
        <img src={Location} alt="Location"></img>
        <h3 className={styles.info}>
          The Granary Hall Centre <br /> 58 Dominic Street <br /> Galway City
        </h3>
      </div>
      <img src={Img} className={styles.image} alt="Butterfly"></img>
      <h3 className={styles.logoExplanation}>
        I use the butterfly as my logo because the butterfly has to go through a
        transformation process to reach its true expression and 'the butterfly
        effect' where a flapping wing represents small changes can have big
        impact.
      </h3>
      <q className={styles.quote}>
        “We delight, in the beauty of the butterfly, but rarely admit the
        changes it has gone through to achieve that beauty”
        <span className={styles.name}> - Maya Angelou</span>
      </q>
    </section>
  );
};

export default Contact;
