import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/navbar/navbar";
import Intro from "../components/intro/intro";
import Quote from "../components/quote/quote";
import About from "../components/about/about";
import Contact from "../components/contact/contact";
import * as styles from "./index.module.scss";

const IndexPage = () => {
  return (
    <div className={styles.outerContainer}>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>Seoige Therapy</title>
        <meta
          name="description"
          content="Counselling and psychotherapy sessions available in Galway Ireland."
        ></meta>
      </Helmet>
      <Navbar />
      <Intro />
      <Quote />
      <About />
      <Contact />
    </div>
  );
};

export default IndexPage;
