import React from "react";
import * as styles from "./intro.module.scss";

const Intro = () => {
  const handleClick = (link: string) => {
    location.hash = "#" + link;
  };
  return (
    <section className={styles.intro}>
      <div className={styles.anchor} id="home-anchor"></div>
      <div className={styles.introDescription}>
        <div className={styles.textAndButtons}>
          <h1 className={`${styles.text} ${styles.intro__textH1}`}>
            Seoige Therapy
          </h1>
          <h2 className={`${styles.text} ${styles.intro__textH2}`}>
            Briedge Joyce
          </h2>
          <h3 className={`${styles.intro__textH3} ${styles.text}`}>
            Psychologist | Psychotherapist
          </h3>
          <div className={styles.buttons}>
            <button
              className={`${styles.btn} ${styles.leftBtn}`}
              onClick={() => handleClick("about-anchor")}
            >
              About
            </button>
            <button
              className={styles.btn}
              onClick={() => handleClick("contact-anchor")}
            >
              Contact
            </button>
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.image}></div>
      </div>
    </section>
  );
};

export default Intro;
